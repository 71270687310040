import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import router from '../router';
import config from '../config';
import { TimetableSearch } from '../views';
import i18n from '../i18n';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

createApp(TimetableSearch)
  .use(router)
  .use(pinia)
  .use(i18n)
  .mount('#timetable-search-overlay');

if (window.location.hash.length <= 2 && config && config.route) {
  router.push(config.route);
}
